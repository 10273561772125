<template>
  <b-modal ref="prompt"
      v-model="activePrompt"
      accept-text="Téléverser"
      cancel-text="Annuler"
      centered
      hide-footer
      scrollable
      title="Téléverser une image">
    <div>
      <div class="m-0">
        <b-form-file
            :disabled="uploadProgression != null"
            accept="image/*"
            class="my-50"
            type="file"
            placeholder="Sélectionner une image"
            drop-placeholder="Déposer une image ici..."
            size="lg"
            @change="selectImage"/>
        <span v-if="uploadProgression != null">
          {{ uploadProgression }} %
        <b-progress
            :value="uploadProgression"
            class="mt-50"
            max="100"
            striped/>
        </span>
      </div>
    </div>

    <cropper
        ref="cropper"
        :src="img"
        :stencilProps="{
        minAspectRatio: minAspectRatio,
        maxAspectRatio: maxAspectRatio,
      }"
        minHeight="10"
        minWidth="10"
        style="max-height: calc(100vh - 360px)"
        @change="changeCrop"
    ></cropper>

    <div class="w-100 d-flex align-items-end justify-content-end mt-1">
      <b-button
          variant="light"
          @click="cancel">
        Annuler
      </b-button>
      <b-button
          class="mx-1"
          variant="primary"
          @click="upload">
        Téléverser
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import {BButton, BFormFile, BModal, BProgress} from "bootstrap-vue";

export default {
  name: "JayUpload",
  components: {
    BButton,
    BFormFile,
    BModal,
    Cropper,
    BProgress,
  },
  props: {
    active: {
      default: false,
      type: Boolean
    },
    minAspectRatio: {
      default: null,
      type: Number
    },
    maxAspectRatio: {
      default: null,
      type: Number
    },
    defaultFullSize: {
      default: true,
      type: Boolean
    },
  },
  data() {
    return {
      activePrompt: false,
      url: process.env.VUE_APP_BACKEND_URL + '/uploads/',
      headers: window.getApiHeaders(),
      img: null,
      formData: new FormData(),
      uploadProgression: null,
      xhr: null,
    }
  },
  mounted() {
    this.activePrompt = this.active
  },
  methods: {
    clear() {
      if (this.xhr != null) {
        this.xhr.abort()
        this.xhr = null
      }
      this.uploadProgression = null
      this.img = null
    },
    cancel() {
      this.clear()
      this.$emit('on-cancel')
    },
    upload() {
      this.activePrompt = true
      if (this.img == null || this.uploadProgression != null) {
        // No file selected
        return
      }
     this.uploadProgression = 0

      this.img = null
      this.xhr = new XMLHttpRequest()

      // Ajax handlers
      this.xhr.onerror = e => {
        this.uploadProgression = null
        this.$emit('on-error', e)
        this.$notify({
          time: 4000,
          title: this.$t('Error'),
          color: 'danger',
          text: "Une erreur technique est survenue lors du téléversement de l'image, veuillez réessayer plus tard"
        })
      }
      this.xhr.onload = e => {
        if (this.xhr.status < 200 || this.xhr.status >= 300) {
          this.uploadProgression = null
          this.$emit('on-error', e)
          this.$notify({
            time: 4000,
            title: this.$t('Error'),
            color: 'danger',
            text: "L'image téléversée est invalide"
          })
        } else {
          let response = JSON.parse(e.target.response)
          response.url = process.env.VUE_APP_BACKEND_URL + "/.." + response.url // Get absolute URL
          this.$emit('on-success', response)
          this.activePrompt = false
          this.$notify({
            time: 4000,
            title: 'Téléversement terminé',
            color: 'primary',
            text: "L'image a bien été téléversée"
          })
        }
      }

      // Upload progression
      if (this.xhr.upload) {
        this.xhr.upload.onprogress = e => {
          if (e.total > 0) {
            let percent = e.loaded / e.total * 100
            this.uploadProgression = Math.trunc(percent)
          }
        }
      }

      // Compute post request with headers
      const headers = window.getApiHeaders()
      this.xhr.withCredentials = true
      this.xhr.open('POST', this.url, true)
      for (let head in headers) {
        if (headers.hasOwnProperty(head) && headers[head] !== null) {
          this.xhr.setRequestHeader(head, headers[head])
        }
      }

      // Send post request with form data
      this.xhr.send(this.formData)
    },
    changeCrop({coordinates}) {
      // Add cropping coordinates to the form data
      this.formData.set('crop_top', coordinates.top)
      this.formData.set('crop_left', coordinates.left)
      this.formData.set('crop_width', coordinates.width)
      this.formData.set('crop_height', coordinates.height)
    },
    selectImage(inputFileEvent) {
      if (inputFileEvent.target.files.length === 0) {
        this.img = null
        return
      }

      if (inputFileEvent.target.files[0].size > 1024 * 1024 * 16) {
        this.img = null
        this.$notify({
          time: 4000,
          title: this.$t('Error'),
          color: 'danger',
          text: "L'image sélectionnée dépasse la taille maximale de 16Mo"
        })
        return
      }

      // Read file content
      let reader = new FileReader()
      reader.onload = e => {
        let imageSource = e.target.result

        // Convert into image
        let img = new Image();
        img.onload = () => {
          this.img = imageSource
          this.formData = new FormData()
          this.formData.append('file', inputFileEvent.target.files[0]);
          if (this.defaultFullSize) {
            // Retrieve image dimensions and set the cropper coordinates accordingly
            this.$refs.cropper.setCoordinates({
              width: img.width,
              height: img.height,
              left: 0,
              top: 0
            })
          }
        }
        // Manage error
        img.onerror = () => {
          this.img = null
          this.$notify({
            time: 4000,
            title: this.$t('Error'),
            color: 'danger',
            text: "L'image sélectionnée est illisible"
          })
        }
        img.src = imageSource.toString();
      }

      reader.readAsDataURL(inputFileEvent.target.files[0])
    },
    onSuccess(imageData) {
      let img = new Image();
      img.onload = () => {
        this.img = "data:image/jpeg;base64," + imageData
        const byteCharacters = atob(imageData)
        let byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'image/jpeg'})
        this.formData = new FormData()
        this.formData.append('file', blob, 'image_plantnet.jpeg')
        if (this.defaultFullSize) {
          // Retrieve image dimensions and set the cropper coordinates accordingly
          this.$refs.cropper.setCoordinates({
            width: img.width,
            height: img.height,
            left: 0,
            top: 0
          })
        }
      }
      img.onerror = () => {
        this.img = null
        this.$notify({
          time: 4000,
          title: this.$t('Error'),
          color: 'danger',
          text: "L'image sélectionnée est illisible"
        })
      }
      img.src = "data:image/jpeg;base64," + imageData
    },
    onFail() {
      this.$notify({
        time: 4000,
        title: 'Erreur camera',
        color: 'danger',
        text: "Une erreur est survenue lors de la prise de la photo"
      })
    },
  },
  watch: {
    active(value) {
      this.activePrompt = value
      this.clear()
    },
    activePrompt(value) {
      this.clear()
      this.$emit('update:active', value)
    }
  },
}
</script>
