import { render, staticRenderFns } from "./CFPQuiz.vue?vue&type=template&id=c2985b30"
import script from "./CFPQuiz.vue?vue&type=script&lang=js"
export * from "./CFPQuiz.vue?vue&type=script&lang=js"
import style0 from "./CFPQuiz.vue?vue&type=style&index=0&id=c2985b30&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports